<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <card class="card-waiting text-center">
        <p class="card-text"></p>
        <div class="author">
          <div class="block block-one"></div>
          <div class="block block-two"></div>
          <div class="block block-three"></div>
          <div class="block block-four"></div>
          <a href="javascript:void(0)">
            <h5 class="title">{{ name }} {{ lastName }}</h5>
          </a>
          <p class="description">Waiting for Approve</p>
        </div>
        <p></p>
        <div class="card-description">
          <p>Your account in process waiting admin for approve and confirm your location request</p>
          <base-button  class="btn-default" v-on:click="signOut()">Log out</base-button>
        </div>

        <div slot="footer" class="button-container">
          <base-button class="btn-facebook" icon round>
            <i class="fab fa-facebook"></i>
          </base-button>
          <base-button class="btn-twitter" icon round>
            <i class="fab fa-twitter"></i>
          </base-button>
          <base-button class="btn-google" icon round>
            <i class="fab fa-google-plus"></i>
          </base-button>
        </div>
      </card>
<!--      <card class="card-lock card-white text-center">-->
<!--        <img slot="header" src="img/emilyz.jpg" alt="..." />-->

<!--        <h4 class="card-title">Joe Gardner</h4>-->
<!--        <base-input-->
<!--          type="password"-->
<!--          placeholder="Password"-->
<!--          addon-left-icon="tim-icons icon-key-25"-->
<!--        >-->
<!--        </base-input>-->

<!--        <base-button slot="footer" type="primary" size="lg" round>-->
<!--          Unlock-->
<!--        </base-button>-->
<!--      </card>-->
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      name: "",
      lastName: ""
    };
  },
  methods: {
    ...mapActions('account', ['logout']),
    signOut() {
      this.logout()

    }
  },
  mounted() {
    const users = localStorage.getItem('users');
    const userData = JSON.parse(users)
    this.name = userData.firstName
    this.lastName = userData.lastName
  }
};
</script>
<style></style>
