<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">User Approve Profile</h2>
    </div>
    <div class="row mt-5">
      <card>
        <template slot="header">
          <h4 class="card-title">{{Model.name}}</h4>
        </template>
        <div>
          <form method="get" action="/" class="form-horizontal">
            <div class="row">
              <label class="col-sm-2 col-form-label">ชื่อ</label>
              <div class="col-sm-10">
                <base-input placeholder="PO" v-model="Model.name"></base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">นามสกุล</label>
              <div class="col-sm-10">
                <base-input placeholder="Barcode" v-model="Model.lastName"></base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">ขนาดความกว้าง</label>
              <div class="col-sm-10">
                <base-input placeholder="Product Name" v-model="Model.DimensionW"></base-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">ขนาดความสูง</label>
              <div class="col-sm-10">
                <base-input placeholder="Product Name" v-model="Model.DimensionH"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" style="text-align: center;">
                <base-button class="mt-auto" native-type="button" type="success" v-on:click="Approve">Approve</base-button>
              </div>
            </div>

          </form>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {Table, TableColumn, Select, Option} from 'element-ui';
import {BasePagination} from 'src/components';
import swal from 'sweetalert2';
import {BaseCheckbox, BaseRadio} from 'src/components/index';
import {mapActions, mapState} from "vuex";

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    BaseRadio
  },
  data() {
    return {
      Model:{
        ID:"",
        name:"",
        lastName:"",
        DimensionH:"",
        DimensionW:""
      }
    };
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('users', ['getUserDetail','adminApprove','clearState']),
    async getUserProfile(){
        await this.getUserDetail(this.$route.params.id)
    },
    Approve(){
      const data = this.Model
      this.adminApprove({data})
    }
  },
  computed: {
    ...mapState('users', ['user','status'])
  },
  created() {
     let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
     console.log("DATA1", date2)
     console.log("DATA2", loginDate)
    if(loginDate.length == 0) {
       this.logout()
    } else {
      if(loginDate != date2) {
         this.logout()
      }
    }

    this.getUserProfile()
  },
  mounted() {
  },
  watch: {
    user(val){
      this.Model.ID = val.ID
      this.Model.name = val.firstName
      this.Model.lastName = val.lastName
      this.Model.DimensionH = val.dimension_height
      this.Model.DimensionW = val.dimension_width
    },
    status(val) {
      console.log(val)
      if(val.message === "success") {
        swal({
          title: `Success Approve!`,
          text: 'User is Approved!',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          type: 'success'
        }).then(_ => {
          this.clearState()
          this.$router.push('/user/list')
        });
      }
    }
  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
</style>
