<template>
  <div>
    <button class="btn btn-primary" @click="print">ปริ้นบาร์โค้ด</button>
    <div id="barcode">
      <!-- <table class="table table-bordered table-sm" width="100.00%">
        <tbody>
          <tr v-for="item in items" :key="item" style="display: block; page-break-before: always;!important;"
            width="100.00%">
            <td v-if="item.column1" style="padding-left:0.2cm" width="33.33%">
              <vue-qrcode :value="item.column1" :scale="2.2" style="margin-left:0.2cm" />
              <div style="font-size: 8px;">{{ item.column1 }}</div>
            </td>
            <td style="padding-left:0.7cm" width="33.33%">
              <div v-if="item.column2">
                <vue-qrcode :value="item.column2" :scale="2.2" style="margin-left:0.7cm;" />
                <div style="font-size: 8px ; margin-left:0.7cm">{{ item.column2 }}</div>
              </div>
              <div v-if="!item.column2" width="33.33%">
                <div style="width: 123.5px;"></div>
              </div>
            </td>
            <td style="padding-left:0.7cm" width="33.33%">
              <div v-if="item.column3">
                <vue-qrcode :value="item.column3" :scale="2.2" style="margin-left:0.7cm" />
                <div style="font-size: 8px ; margin-left:0.7cm">{{ item.column3 }}</div>
              </div>
              <div v-if="!item.column3" width="33.33%">
                <div style="width: 123.5px;"></div>
              </div>

            </td>

          </tr>

        </tbody>
      </table> -->
      <table class="table table-bordered table-sm" width="100.00%" style="width:100%">
        <tbody>
          <tr v-for="item in items" :key="item" style="display: block; page-break-before: always;!important;"
            width="100.00%">
            <td v-if="item.column1" style="padding-left:0.2cm;width:33%" width="33.33%">
              <qr-code :text="item.column1" :size="62" style="margin-left:0.2cm"></qr-code>
              <div style="font-size: 8px;">{{ item.column1 }}</div>
            </td>
            <td style="padding-left:0.7cm" width="33.33%">
              <div v-if="item.column2">
                <qr-code :text="item.column2" :size="62" style="margin-left:0.7cm"></qr-code>
                <div style="font-size: 8px ; margin-left:0.7cm">{{ item.column2 }}</div>
              </div>
            </td>
            <td style="padding-left:0.7cm" width="33.33%">
              <div v-if="item.column3">
                <qr-code :text="item.column3" :size="62" style="margin-left:0.7cm"></qr-code>
                <div style="font-size: 8px ; margin-left:0.7cm">{{ item.column3 }}</div>

              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
<script>
import VueBarcode from 'vue-barcode';
import VueQrcode from 'vue-qrcode'
import Vue from 'vue'
import VueQRCodeComponent from 'vue-qrcode-component'
import { mapActions, mapState } from "vuex";
Vue.component('qr-code', VueQRCodeComponent)

export default {
  components: {
    VueQrcode
  },
  data() {
    return {
      output: null,
      barcodeValue: "",
      barcodeValue2: "",
      items: []
    }
  },
  computed: {
    ...mapState('receive', ['BarcodeList'])
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('receive', ['getBarcodeByRecNo2']),
    renderBarcode() {
      this.barcodeValue = this.$route.params.recNo
      this.barcodeValue2 = this.$route.params.barCode
    },
    print() {
      this.$htmlToPaper('barcode')
    }
  },
  mounted() {
    //this.print()
    const recNo = this.barcodeValue
    const BarCode = this.barcodeValue2


    this.getBarcodeByRecNo2({ recNo, BarCode })
    //setTimeout(() =>   this.print(), 2000);


  },
  created() {
    let loginDate = localStorage.getItem('loginDate');
    const current = new Date();
    const date2 = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    console.log("DATA1", date2)
    console.log("DATA2", loginDate)
    if (loginDate.length == 0) {
      this.logout()
    } else {
      if (loginDate != date2) {
        this.logout()
      }
    }

    this.renderBarcode()
  },
  watch: {
    BarcodeList(val) {

      console.log(val)

      this.items = val.items.data.reduce((acc, col, i) => {
        if (i % 3 === 0) {
          console.log({ column1: col });
          acc.push({ column1: col });
          acc[acc.length - 1].column1 = col;
        } else if (i % 3 === 1) {
          console.log(acc);
          // acc.push({column2: col});
          acc[acc.length - 1].column2 = col;

        } else {
          // acc.push({column3: col});
          acc[acc.length - 1].column3 = col;
        }
        return acc;
      }, []);

      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].column2 === []) {
          this.items[i].column2 = []
        }
        if (this.items[i].column3 === []) {
          this.items[i].column3 = []
        }
      }

    }
  }
}
</script>
  
<style>
/*style=""*/
</style>
  