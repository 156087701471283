<template>
  <div>
    <button class="btn btn-primary" @click="print">ปริ้นบาร์โค้ด</button>
    <div id="barcode">
      <VueBarcode v-bind:value="barcodeValue">
        rendering fails.
      </VueBarcode>
    </div>
  </div>

</template>

<script>
import VueBarcode from 'vue-barcode';
export default {
  components:{
    VueBarcode
  },
  data () {
    return {
      output: null,
      barcodeValue:"",
    }
  },
  methods: {
    renderBarcode(){
      this.barcodeValue = this.$route.params.barcode
    },
    print(){
      this.$htmlToPaper('barcode')
    }
  },
  mounted() {
    this.print()
  },
  created() {
    this.renderBarcode()
  }
}
</script>

<style scoped>

</style>
